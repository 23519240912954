import React, { useContext } from "react"
import { css } from "@emotion/react"
import moment from "moment"
import PageContainer from "../../components/atom/PageContainer"
import PageTitleText from "../../components/atom/PageTitleText"
import EmptyItem from "../../components/molecule/EmptyItem"
import EventProducts from "../../components/molecule/EventProduct"
import ProductItem from "../../components/molecule/ProductItem"
import StoreHours from "../../components/molecule/StoreHours"
import Layout from "../../components/organism/Layout"
import SEO from "../../components/SEO"
import IndexContextProvider from "../../contexts/IndexContext"
import useEventProduct from "../../contexts/IndexContext/hooks/useEventProduct"
import { DataType, Event } from "../../types"
import { EVENT_PRODUCT_SECTION_TITLE, PAGE } from "../../values"
import { globalContext } from "../../contexts/GlobalContext"

const styles = require("./style.module.scss")

const exampleEvent: Event = {
  id: "123",
  name: "발렌타인데이 행사",
  startDate: moment().format("YYYY-MM-DD"),
  endDate: moment().format("YYYY-MM-DD"),
  isActive: true,
}

const EventProduct = () => {
  const eventProducts = useEventProduct()
  const event = useContext(globalContext)?.eventHook.data
  function handleDisplayEventTerm() {
    const start = moment(event?.startDate)
    const end = moment(event?.endDate)

    if (start.month() === end.month() && start.date() === end.date()) {
      return "오늘 하루만"
    } else {
      return (
        moment(event?.startDate).format("MM/DD") + " - " + moment(event?.endDate).format("MM/DD")
      )
    }
  }
  return (
    <Layout nowPage={PAGE.EVENT_PRODUCT}>
      <IndexContextProvider>
        <SEO title="행사 제품" />
        <div className={styles["backgroundImage"]}>
          <img
            className={styles["saleImage"]}
            src={require("../../images/event-background-tile.svg")}
          />
          <img
            className={styles["saleImage2"]}
            src={require("../../images/event-background-tile.svg")}
          />
          <img
            className={styles["saleImage2"]}
            src={require("../../images/event-background-tile.svg")}
          />
        </div>
        <div className={styles["titleContainer"]}>
          <PageTitleText
            isNotBold
            title={event ? event.name : "행사제품"}
            style={{ 
              marginRight: 15,
              marginTop: 5
            }}
          />
          <span className={styles["dateText"]}>({handleDisplayEventTerm()})</span>
        </div>
        {eventProducts.data && eventProducts.data.length != 0 ? (
          <div className={styles["productContainer"]}>
            {eventProducts.data.map((item, index) => (
              <EventProducts product={item} />
            ))}
          </div>
        ) : null}
      </IndexContextProvider>
    </Layout>
  )
}

export default EventProduct
